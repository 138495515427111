.horizontal-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  /* Set the list as a flex container */
}

.horizontal-list li {
  margin-right: 10px;
  /* Add spacing between list items */
}

.rows {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

/* Style for each column */
.col {
  width: calc(33.33% - 20px);
  /* Adjust column width as needed with margin */
  padding: 20px;
  box-sizing: border-box;

  margin: 10px;
  /* Add margin for distance between columns */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add shadow effect */
}