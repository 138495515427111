/* Header Styles */
header {
    background-color: #fff;
    color: #000;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo img {
    width: 50px; /* Adjust according to your logo size */
    height: auto;
    margin-right: 10px;
  }
  
  .logo span {
    font-size: 1.5rem;
  }
  
  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin-right: 50px;
  }
  
  nav ul li {
    margin-right: 20px;
  }
  
  nav ul li a {
    color: #000;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
    
  }
  
  nav ul li a:hover {
    text-decoration: underline;
  }